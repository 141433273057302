import axios from "axios";

//接続URL
let url = process.env.VUE_APP_API_ORIGIN+"/api_mailregist/";

export default {
	checkKey: async function(callback,key) {
        await axios.get(url + "?key="+key).then(response => callback(response));
    },
	fetch: async function(callback,no) {
        await axios.get(url + "?no="+no).then(response => callback(response));
    },
	fetchList: async function(callback,query) {
		await axios.get(url + "?query="+query).then(response => callback(response));
	},
};
